import { ARTIFACT_TYPES, ARTIFACT_TYPE_INTERVIEW_TAB_MAP } from "../constants";

export const getTabForInsight = (insight) => {
    try {
        const quote = JSON.parse(insight?.quote);
        const artifactType = quote?.source;

        return artifactType === "note"
            ? ARTIFACT_TYPE_INTERVIEW_TAB_MAP[ARTIFACT_TYPES.INTERVIEW_NOTE]
            : ARTIFACT_TYPE_INTERVIEW_TAB_MAP[ARTIFACT_TYPES.TRANSCRIPT];
    } catch {
        return ARTIFACT_TYPE_INTERVIEW_TAB_MAP[ARTIFACT_TYPES.INTERVIEW_NOTE];
    }
};
