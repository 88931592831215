import { ACTIVITY_FEED_TYPES, HYPOTHESIS_ACTIVITIES } from "../constants";
import { IActivity } from "../stores/jotai/base";

const isValidCanvasActivity = (activity: IActivity | ActivityFeedItem) => {
    return (
        activity.name !== ACTIVITY_FEED_TYPES.HYPOTHESIS_BULK_CREATE &&
        Object.values(HYPOTHESIS_ACTIVITIES).includes(activity.name)
    );
};

export default isValidCanvasActivity