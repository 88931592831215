import { atom } from "jotai";
import { noop } from "lodash";

interface v3Migration {
    migratedToV3: boolean;
    canCancelMigrationAlert: boolean;
    onSuccessfulMigration: Function;
}

export const INITIAL_V3_MIGRATION_STATE: v3Migration = {
    migratedToV3: false,
    canCancelMigrationAlert: false,
    onSuccessfulMigration: noop,
};

export const v3MigrationAtom = atom(INITIAL_V3_MIGRATION_STATE);

export const setV3MigrationStatus = (setState, isMigrated) => {
    setState((state: v3Migration) => {
        const newState = { ...state };

        newState.migratedToV3 = isMigrated;

        return newState;
    });
};

export const setCanCancelMigrationAlert = (setState, canCancel) => {
    setState((state: v3Migration) => {
        const newState = { ...state };

        newState.canCancelMigrationAlert = canCancel;

        return newState;
    });
};

export const setOnSuccessfulMigration = (setState, onSuccess) => {
    setState((state: v3Migration) => {
        const newState = { ...state };

        newState.onSuccessfulMigration = onSuccess;

        return newState;
    });
};
