import { Box, Stack, SxProps, Typography } from "@mui/material";
import React, { FC, useEffect, useMemo, useState } from "react";
import IwFullLogo from "./IwFullLogo";
import scssVars from "../../../scss/variables.module.scss";
import { useAtomValue } from "jotai";
import { DiscoverySessionState, discoverySessionAtom } from "../../stores/jotai/discovery-session";

interface IwSplashScreenProps {
    visible: boolean;
}

export const SPLASH_SCREEN_WRAPPER_STYLES: SxProps = {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000000000000,
    backgroundColor: scssVars.hoverOptionColor,
    color: scssVars.mainSecondaryColor,
    transition: "opacity 0.3s ease",
};

const SPLASH_SCREEN_LOADER_STYLES: SxProps = {
    color: scssVars.mainSecondaryColor,
    position: "relative",
    fontSize: "11px",
    background: scssVars.mainSecondaryColor,
    animation: "escaleY 1s infinite ease-in-out",
    width: "0.6em",
    height: "2em",
    animationDelay: "-0.16s",
    ":before, :after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: "1.8em",
        background: scssVars.mainSecondaryColor,
        width: "0.6em",
        height: "2em",
        animation: "escaleY 1s infinite ease-in-out",
    },
    ":before": {
        left: "-1.8em",
        animationDelay: "-0.32s",
    },

    "@keyframes escaleY": {
        "0%, 80%, 100%": {
            boxShadow: "0 0",
            height: "2em",
        },
        "40%": {
            boxShadow: "0 -2em",
            height: "3em",
        },
    },
};

const IwSplashScreen: FC<IwSplashScreenProps> = (props) => {
    const { visible } = props;
    const [shouldShow, setShouldShow] = useState<boolean>(false);
    const discoverySession = useAtomValue(discoverySessionAtom);

    const splashScreenLoader = useMemo(() => {
        return (
            <Box sx={{ height: "3em" }}>
                <Box sx={SPLASH_SCREEN_LOADER_STYLES} />
            </Box>
        );
    }, []);

    const getLoadingText = () => {
        if (discoverySession.state === DiscoverySessionState.loading_org) {
            return "Loading organization data...";
        }

        if (discoverySession.state === DiscoverySessionState.loading_group) {
            return "Loading team data...";
        }

        return "Unveiling insights...";
    };

    useEffect(() => {
        let timeoutID = null;

        if (visible) {
            setShouldShow(true);
        } else {
            timeoutID = setTimeout(() => {
                setShouldShow(false);
            }, 300);
        }

        return () => {
            clearTimeout(timeoutID);
        };
    }, [visible]);

    return (
        !!shouldShow && (
            <Stack
                spacing={7}
                sx={{
                    ...SPLASH_SCREEN_WRAPPER_STYLES,
                    opacity: visible ? 1 : 0,
                }}
            >
                <IwFullLogo />
                <Stack
                    sx={{
                        alignItems: "center",
                    }}
                >
                    {splashScreenLoader}
                    <Typography sx={{ fontWeight: 400, fontSize: "18px" }}>
                        {getLoadingText()}
                    </Typography>
                </Stack>
            </Stack>
        )
    );
};

export default IwSplashScreen;
